@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@1,300&display=swap');
.login-bg {
  display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: #E6E6E6; */
    font-family: 'Jost', sans-serif !important;
    position: relative;
    /* top: 5%; */
    width: 696px;
    height: 490px !important;
    padding-top: 16vh;
    padding-bottom: 0;
}

.login-cont {
  width: 100%;
  height: 460px;
  background-color: #161616;
  border-radius: 16px;
  z-index: 2;
  margin-left:-1%;
  position: absolute;
  box-sizing: border-box;
  /* top: 50%; */
  /* transform: translate(0, -50%); */
  margin-bottom: 0rem;
}
.login-cont-bg{
  width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    border-radius: 16px;
    background-color: #161616;
}
.login-cont-border{
  background-color: black;
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    transform: translate(0, 0.8%);
    border-radius: 16px;
    box-shadow: 0px 5px 12px -1px #000000d1;
}
.login-cont-left{
  background-color: #161616;
    width: 46%;
    border-radius: 15px 0px 0px 15px;
    height: 100%;
    position: absolute;
    padding: 6px;
}
.login-cont-right{
  background-color: #161616;
    width: 50%;
    border-radius: 0px 15px 15px 0px;
    height: 100%;
    right: 0;
    position: absolute;
    padding: 6px;
    overflow:hidden;
}
.login-cont-left img{
  width: 100%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
}
.login-svg-1 {
  position: absolute;
    left: 0;
    bottom: 0;
    max-width: 250px;
    max-height: 196px;
    min-width: 224px;
    min-height: 174px;
}
.login-svg-2 {
  right: 0;
    position: absolute;
    bottom: 0;
    min-width: 358px;
    max-width: 522px;
    min-height: 462px;
    max-height: 508px;
}

.login-svg-3 {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-14%, -38%);
  /* width: 56vh; */
  /* height: 56vh; */
  min-height: 580px;
  min-width: 572px;
}

.login-svg-4 {
  position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(100%, -25vh);
    width: 6%;
    height: 6%;
    z-index:1;
}
.login-svg-5{
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-200px, -547px);
  width: 8%;
  height: 8%;
}
.login-right-bookmark{
  position: absolute;
    top: 0;
    left: 18px;
    width: 10%;
    height: 10%;
}
.login-right-logo{
  width: 40%;
    right: 8%;
    top: 3%;
    position: absolute;
}
.login-form-cont{
  padding: 12px;
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  right: 0;
  display: flex;
  flex-flow: column;
  bottom:5%;
  /* align-items: center; */
}
.login-header{
  font-size: 2.1em;
  color: white;
  font-family: 'Jost', sans-serif !important;
  letter-spacing: 0.3rem;
  margin-top:0.7rem;
  font-weight: bolder;

}
.login-label{
  color: white;
  font-family: inherit;
  font-weight: 100;
  letter-spacing: 3px;
  margin-bottom: .4rem;
}
.input{
  font-family: revert !important;
    font-size: 0.9rem;
    color: black;
    letter-spacing: 0.1rem;
}
::placeholder{
  font-family:'Jost', sans-serif;
}
.input:focus{
  outline: none;
}
.login-form{
  display: flex;
    flex-flow: column;
    width: 92%;
}
.inputContainer {
  display: block;
    width: 80%;
    z-index: 3;
}
.inputContainer input{
  width: 100%;
  height: 2.2rem;
  border-bottom: 4px solid #3aa4b0;
  border-radius: 6px;
  padding-left: 6px;
}
.submitBtnConts{
  width: 86px;
    min-height: 42px;
    border-radius: 24px;
    background-color: #3aa4b0;
    border: transparent;
    margin-bottom: 17px;
    order: 2;
    margin-right: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    z-index: 3;
    margin-left: auto;
    transition: 1s;
    transition-duration: 0.8s;
    transition-delay: 0s;
}
.submitBtnConts input{
  background-color: transparent;
  border: none;
  text-align: center;
    letter-spacing: 3px;
    font-weight: bold;
    transition: 0.5s;
    transition-duration: 0.5s;
    transition-delay: 0s;

}
.submitBtnConts:hover{
  cursor: pointer;
}
.submitBtnConts:hover input{
  color:white;
  cursor: pointer;
}
.submitBtnConts:hover{
  transform:scale(1.05) ;
}
.join-text a{
  z-index: 4;
  color:#3aa4b0;
  transition: 0.1s;
    transition-duration: 0.4s;
    transition-delay: 0s;
}
.join-text a:hover{
  z-index: 4;
  color:#13e7ff;
}
.join-text{
  color:white;
  margin-top: .7rem;
}
.join-text{
  z-index: 4;
}
/* .submitBtn:hover{
  color: white;
  cursor: pointer;
  background-color: #9867C5;
} */
.blog-recent-cont{
  height: 90vh;
}
.recent-cont{
  height: 100%;
}
.blog-post-cont{
  border-radius: 10px;
  box-shadow: 0px 0px 6px -2px #00000087;
  background-color: rgb(237 237 237);
}
.blog-post-img-cont{
  height: 76%;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}
.blog-post-img-cont img{
  min-height: 100%;
  width: 100%;
}
.hero-bg-gradient{
  background-image: linear-gradient(89deg, black,black,black,black,#000000,transparent);
    height: 100%;
    position: absolute;
    width: 48%;
    transform: rotate(26deg)scale(2.5)translate(-186px, 103px);
    filter: opacity(0.85);
}
.hero-img-logo-cont{
  position: absolute;
  top:40%;
  /* left:10%; */
  visibility: hidden;
}
.hero-animation1{
  visibility: hidden;
  opacity: 0;
  animation-name: hero1;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
@keyframes hero1 {
  0% {visibility: hidden;opacity: 0;top:50%;}
  100% {visibility: visible;opacity: 1; top:15%;/*position: fixed;*/}
}
.hero-text-cont1{
  position: absolute;
  width: auto;
  visibility: hidden;
}
.hero-animation2{
  visibility: hidden;
  opacity: 0;
  top:60%;
  animation-name: hero2;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
@keyframes hero2 {
  0% {visibility: hidden;opacity: 0;top:60%;}
  100% {visibility: visible;opacity: 1; top:28%;/*position: fixed;*/}
}
.hero-main-cont{
  position: absolute;
  left: 10%;
  top: 18%;
  height: 76%;
}
.hero-text-cont1 h1{
  color:white;
  letter-spacing: 8px;
  text-align: left;
}
.hero-text-cont2{
  position: absolute;
  width: auto;
  visibility: hidden;
}
.hero-text-cont2 h1{
  color:white;
  letter-spacing: 8px;
  text-align: left;
}
.hero-animation3{
  visibility: hidden;
  opacity: 0;
  top:80%;
  animation-name: hero3;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
.navbar1-scroll{
  color: #252525 !important;
  font-weight: 500 !important;
}
.navbar1-scroll-re{
  color: white !important;
  font-weight: 100 !important;
}
.navbar1{
  top:0px !important;
}
.display-none{
  display: none;
}
@keyframes hero3 {
  0% {visibility: hidden;opacity: 0;top:80%;}
  100% {visibility: visible;opacity: 1; top:46%;/*position: fixed;*/}
}
.hero-text-cont3{
  position: absolute;
  width: auto;
  visibility: hidden;
}
.hero-text-cont3 h1{
  color:white;
  letter-spacing: 8px;
  text-align: left;
}
.hero-animation4{
  visibility: hidden;
  opacity: 0;
  top:80%;
  animation-name: hero4;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
@keyframes hero4 {
  0% {visibility: hidden;opacity: 0;top:90%;}
  100% {visibility: visible;opacity: 1; top:56%;/*position: fixed;*/}
}
@media only screen and (max-width: 770px) and (min-width: 603px) {
  .book-card {
    min-width: 80%;
  }
  .login-bg-svg-cont .login-svg-2{
    display: none !important;
  }
  .login-bg{
    padding-top: 12vh;
  }
  .book-card-cont {
    transform: translate(-70%, 0%);
  }
  .login-cont{
    width:90%;
    margin-left: 5%;
  }
  .login-bg{
    width:98%;
}
}
@media only screen and (min-width: 771px) {
  .book-card {
    min-width: 72%;
  }
  .inner-card {
    max-width: 60%;
  }
  .book-card-cont {
    transform: translate(-60%, 0%);
  }
}
/* @media only screen and (max-width: 699px) and (min) {
  .book-card {
    min-width: 72%;
  }
  .inner-card {
    max-width: 60%;
  }
  .book-card-cont {
    transform: translate(-60%, 0%);
  }
} */
@media only screen and (max-width: 602px) {
    .book-card {
      width: 60vw;
      height: 100% !important;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
    }
  
    .inner-card-cont {
      position: unset !important;
      height: 65% !important;
      min-width: 100%;
      margin-top: 18%;
    }
    .login-cont-left{
        display: none;
    }
    .submitBtnConts{
      margin-right: 10%;
    }
    .login-cont-right{
        width: 100%;
        border-radius: 12px;
    }
    .login-bg{
        width:80%;
        padding-top: 10vh;
    }
    .login-form-cont{
        align-items: center;
    }
    .login-form{
        width:85%;
    }
    .join-text{
        font-size:15px;
    }
    .login-bg-svg-cont .login-svg-2{
        display: none;
    }
    .book-scroll-cont {
      height: 100% !important;
    }
  
    .inner-card {
      position: unset;
      width: 142px;
      height: 100%;
      padding: 0;
      box-shadow: none;
      border: 4px solid;
      object-fit: fill;
    }
  
    .book-detail-cont {
      display: none;
    }
  
    .book-card-cont {
      transform: translate(-13%, 0%);
    }
  
    .slider-switch {
      width: 40px;
      height: 40px;
    }
  }
  @media only screen and (max-height: 580px){
    .login-bg{
      padding-top:3rem;
    }
    .login-svg-1{
      display: none;
    }
    .login-bg-svg-cont .login-svg-2{
      display:none;
    }
  }
  @media only screen and (min-height: 668px) and (max-height:963px){
    .login-bg{
      padding-top:18vh;
    }
  }
  @media only screen and (min-height: 964px){
    .login-bg{
      padding-top:32vh;
    }
  }

.header {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
}

.text {
  height: 10px;
  background-color: white;
  border: 1px solid black;
}

.bookImage {
  height: 10px;
  background-color: white;
  border: 1px solid black;
}