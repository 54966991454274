/* * {
  /* font-family: sans-serif;
} */
.slidecontainer {
  width: 100%;
  /* Width of the outside container */
}
.price_form_wrap {
  display: flex;
  margin: 50px auto 0;
  max-width: 980px;
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 767px) {
  .price_form_wrap {
    display: block;
  }
}
.price_form_wrap:after {
  clear: both;
  content: "";
  display: block;
}
.selection-wrap {
  width: 60%;
  padding: 20px;
}
@media (max-width: 767px) {
  .selection-wrap {
    width: 100%;
  }
}
.total-wrap {
  width: 40%;
  padding: 20px;
  position: relative;
}
@media (max-width: 767px) {
  .total-wrap {
    width: 100%;
  }
}
.total-wrap:before {
  content: "";
  display: block;
  height: 80%;
  background: #222;
  opacity: 0.1;
  width: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
label {
  margin: 10px 0 5px;
  display: block;
  font-size: 16px;
}
.total {
  display: block;
  margin: 40px 0;
  line-height: 40px;
}
.total span {
  display: block;
  text-align: center;
}
.total span:nth-last-child(1) {
  text-align: center;
  font-size: 42px;
}
.review > div {
  line-height: 1.4;
}
.review > div#add-ons {
  margin-top: 15px;
  font-size: 22px;
}
/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 10px;
  /* Specified height */
  background: #d3d3d3;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s;
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  margin: 10px 0 30px;
}
/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}
/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 20px;
  /* Set a specific slider handle width */
  height: 20px;
  /* Slider handle height */
  background: #4B9DEA;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 50%;
}
.slider::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  background: #4B9DEA;
  cursor: pointer;
  /* Cursor on hover */
}
.input_container:after {
  clear: both;
  content: "";
  display: block;
}
/* TOGGLE STYLING */
.toggle {
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  font-size: 0;
  display: block;
}
.toggle:after {
  clear: both;
  content: "";
  display: block;
}
.toggle input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.toggle input:hover {
  cursor: pointer;
}
.toggle input + label {
  margin: 0 0.5%;
  padding: 0.75rem 0rem;
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  border: solid 1px #DDD;
  background-color: #FFF;
  font-size: 18px;
  width: 30.333%;
  line-height: 140%;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out, box-shadow 0.15s ease-out;
}
.toggle input + label:hover {
  cursor: pointer;
}
.toggle input + label span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.toggle input:hover + label {
  border-color: #213140;
}
.toggle input#seo:hover + label {
  cursor: no-drop;
}
.toggle input:checked + label {
  background-color: #4B9DEA;
  color: #FFF;
  box-shadow: 0 0 10px rgba(102, 179, 251, 0.5);
  border-color: #4B9DEA;
  z-index: 1;
}
.toggle input:focus + label {
  outline: dotted 1px #CCC;
  outline-offset: 0.45rem;
}
.h2-styling{
  padding-bottom: 5%;
}

.padding-bottom{
  padding-bottom: 8%;
}